'use client';

import { atom, useAtomValue, useSetAtom } from 'jotai';
import { appProtocol_openQR, TAppBarcodeResponse } from './app_protocol';
import { getLocalStorageBool, setLocalStorageBool } from '../../utils/cookie';


export const CONST_USE_WEB_SCANNER = false;

// export const CONST_USE_WEB_SCANNER = true;

type TAtomTypes = {
  isLoading: boolean;

  enableWebScanner: boolean
  isOpenWebScanner: boolean
};

//스캐너에서 바로 들어오는 함수 전달받기
export const g_barcode_use:{
  //open 클릭으로 등록한넘
  onReadCode:((param:TAppBarcodeResponse)=>void)|null;

  //상시 read로 등록한넘
  onAutoReadCode:((param:TAppBarcodeResponse)=>void)|null;
} = {
  onReadCode: null,
  onAutoReadCode: null,
}

const loadingAtom = atom<TAtomTypes>({
  isLoading: false,
  isOpenWebScanner: false,
  enableWebScanner: false,
});


const keyEnableBarcode = `deviceEnableBarcode`;

export function useBarcodeReader() {
  const state = useAtomValue(loadingAtom);
  const setState = useSetAtom(loadingAtom);

  const openBarcodeReader = ({
    onReadCode,
  }:{
    onReadCode: typeof g_barcode_use['onReadCode']
  }) => {
    g_barcode_use.onReadCode = onReadCode;
    setState({
      ...state,
      ...(CONST_USE_WEB_SCANNER && state.enableWebScanner ? {isOpenWebScanner: true} : {}),
      isLoading: true,
    });
    if((!CONST_USE_WEB_SCANNER || !state.enableWebScanner)){
      appProtocol_openQR();
    }
  };

  const closeBarcodeReader = () => {
    g_barcode_use.onReadCode = null;
    setState({
      ...state,
      isLoading: false,
      isOpenWebScanner: false,
    });
  };


  const loadWebSetting = ()=>{
    const bEnable = getLocalStorageBool(keyEnableBarcode);
    setState({
      ...state,
      enableWebScanner: bEnable,
    });
    return bEnable;
  }
  const saveWebSetting = (bEnable:boolean)=>{
    setLocalStorageBool(keyEnableBarcode, bEnable);
    setState({
      ...state,
      enableWebScanner: bEnable,
    });
  }

  return {
    ...state,
    openBarcodeReader,
    closeBarcodeReader,
    loadWebSetting, saveWebSetting
  };
}
