
export interface TAppBarcodeResponse {
    status: 'ok' | 'cancel'
    code: string
}

const app_protocol: {
    fn_barcode: ((param: TAppBarcodeResponse) => void) | null
    debug_action?: string,
} = {
    fn_barcode: null,
};

interface TNativeInvokerParam {
    name: string,
    data: {
        result: string
    }
}
declare global {
    interface Window {
        NativeInvoker: (param:TNativeInvokerParam) => void
    }

}

export function setAppProtocolRecevier(cb: typeof app_protocol['fn_barcode']) {
    app_protocol.fn_barcode = cb;
}

export function init_app_protocol() {
    // QR코드 리스너
    const NativeInvoker = (param: TNativeInvokerParam) => {
        console.log(`📟 onAppMessage: ${param.name}`, param);

        if (param.name === "openQR") {
            const code = param.data.result;
            if (app_protocol.fn_barcode) app_protocol.fn_barcode({
                status: code ? 'ok' : 'cancel',
                code,
            });
        }
    };

    Object.assign(window, { NativeInvoker });
}

export const appProtocol_isApp = () => {
    if(app_protocol.debug_action) return true;

    const { userAgent } = navigator;

    if (userAgent.includes("MARKET_GOOGLE")) {
        const { idevel_app: idevelApp } = window as any;
        return !!idevelApp;
    } else if (userAgent.includes("MARKET_APPSTORE")) {
        const { webkit } = window as any;
        return !!webkit?.messageHandlers?.idevel_app;
    }
    return false;
}

// 처음 화면 App Splash 제거
export const appProtocol_removeSplash = () => {
    const { userAgent } = navigator;

    if (userAgent.includes("MARKET_GOOGLE")) {
        const { idevel_app: idevelApp } = window as any;
        idevelApp?.removeSplash();
    } else if (userAgent.includes("MARKET_APPSTORE")) {
        const reqApp = {
            name: "removeSplash",
        };
        const { webkit } = window as any;
        webkit?.messageHandlers?.idevel_app?.postMessage(reqApp);
    }
};


// AOS의 경우 idealApp.openQR()실행
// IOS의경우 webkit?.messageHandlers?.idevel_app?.postMessage( {name: "openQR" });
export const appProtocol_openQR = () => {
    console.log('appProtocol_openQR')
    if (app_protocol.debug_action && debug_app_openQR()) {
        return;
    }

    const { userAgent } = navigator;
    if (userAgent.includes("MARKET_GOOGLE")) {
        const { idevel_app: idevelApp } = window as any;
        idevelApp?.openQR();
    } else if (userAgent.includes("MARKET_APPSTORE")) {
        const reqApp = {
            name: "openQR",
        };
        const { webkit } = window as any;
        webkit?.messageHandlers?.idevel_app?.postMessage(reqApp);
    }
};


export function debug_initappChannel(behavior: string) {
    if(behavior == 'none') app_protocol.debug_action= null;
    else app_protocol.debug_action = behavior;
}

export function debug_app_openQR() {
    (async ()=>{
        const code = app_protocol.debug_action == 'cancel' ? '' : app_protocol.debug_action;

        //일정 시간 이후에 대답 해주자.
        await sleep_ms(100);

        window.NativeInvoker({ name: 'openQR', data: { result: code }});
    })();
    return true;
}


function sleep_ms(miliseconds: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, miliseconds);
    });
}


