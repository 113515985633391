'use client';

import { useCallback, useEffect, useState } from 'react';
import cn from '@/utils/tailwind/utils';
import LoadingCylonIcon from '../icons/loading-cylon';
import {
  appProtocol_isApp,
  appProtocol_removeSplash,
  init_app_protocol,
  setAppProtocolRecevier,
  TAppBarcodeResponse,
} from './app_protocol';
import { CONST_USE_WEB_SCANNER, g_barcode_use, useBarcodeReader } from './use-barcode';
import { Button } from 'rizzui';
import { PiBarcode } from 'react-icons/pi';
import { api_call } from '../../composables/api/api_call';
import { ShowAlert } from '../alerts';
import { useNotiDialog } from '../modal-views/noti-dialog';
import { REBarcodeInventory, REBarcodeProductRegister, REBarcodeStockIn } from './barcode.interface';
// import { ParsedBarcode, parseQRBarcode } from './qr_parse';

export default function GlobalBarcodeLoading() {
  const { isLoading, closeBarcodeReader } = useBarcodeReader();

  const [classVisible, setClassVisible] = useState<string | null>(null); // 애니메이션 처리를 위해 로컬 상태 사용

  useEffect(() => {
    init_app_protocol();
    appProtocol_removeSplash();
    setAppProtocolRecevier(fn_barcode);

    return () => {
      setAppProtocolRecevier(null);
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      setClassVisible('opacity-0 fade-in');
    } else {
      setClassVisible('fade-out');
      const timer = setTimeout(() => setClassVisible(null), 500);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  //스캐너에서 바로 들어오는 함수
  const fn_barcode = (param: TAppBarcodeResponse) => {
    console.log(`📟 onReadCode: `, param);

    if (g_barcode_use.onReadCode) {
      g_barcode_use.onReadCode(param);
    } else if (g_barcode_use.onAutoReadCode) {
      g_barcode_use.onAutoReadCode(param);
    }
    closeBarcodeReader(); 
  };

  if (!classVisible) return null;

  return (
    <div
      className={cn(
        'fixed flex flex-col items-center justify-center left-0 right-0 top-0 bottom-0 bg-black/60 text-gray-200 z-[10000] select-none',
        classVisible
      )}
    >
      <LoadingCylonIcon className='w-64 h-64 text-gray-100' />
      <p className='text-2xl font-bold'>Barcode Reading...</p>
    </div>
  );
}

interface Props {
  onBarcode: (
    code: string | null,
    searchResult?: REBarcodeProductRegister | REBarcodeStockIn | REBarcodeInventory
  ) => void;
  searchType?: 'product-reg' | 'stock-in' | 'inventory' | 'only-code';
  text?: string;
  className?: string;
  locationId?: string | (() => string);
  autoWaitReader?: boolean;
}

export function BarcodeReadButton({
  onBarcode,
  searchType,
  text,
  className,
  locationId,
  autoWaitReader = true,
}: Props) {
  const [hasReader, setHasReader] = useState(false);
  const { enableWebScanner, openBarcodeReader } = useBarcodeReader();
  const { noInventoryItem, noRegisteredItem } = useNotiDialog();

  useEffect(() => {
    setHasReader(appProtocol_isApp() || (CONST_USE_WEB_SCANNER && enableWebScanner));
  }, [enableWebScanner]);

  const onReadCode = useCallback(async (param: TAppBarcodeResponse) => {
    if (param.status == 'ok') {
      if (searchType != 'only-code') {
        const response = await api_call('/barcode/search', {
          param: {
            code: param.code,
            type: searchType,
            locationId: getLocationId(),
          },
          on_error(err) {
            if (err.key == 'err_data' || err.key == 'invalid_data') {
              ShowAlert('KPIS에 등록되지 않은 바코드입니다.');
              return true;
            }
            if (err.key == 'not_support') {
              ShowAlert('위치를 먼저 선택해주세요');
              return true;
            }
            if (err.key == 'no_data') {
              if(searchType == 'stock-in'){
                noRegisteredItem();
              }
              else{
                noInventoryItem();
              }
              return true;
            }
          },
        });

        onBarcode(response.result?.parsed?.standardCode ?? param.code, response.result);
      } else {
        onBarcode(param.code, null);
      }
    } else {
      onBarcode(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoWaitReader) {
      console.log(`💊💊💊set onReadCode`, onReadCode);

      g_barcode_use.onAutoReadCode = onReadCode;
      return () => {
        if (g_barcode_use.onAutoReadCode == onReadCode) {
          console.log(`💊💊💊release onReadCode`, onReadCode);
          g_barcode_use.onAutoReadCode = null;
        } else {
          console.log(`💊💊💊skip release onReadCode`, onReadCode);
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationId = () => (typeof locationId == 'function' ? locationId() : locationId);

  const onClick = () => {
    if (searchType == 'inventory' && !getLocationId()) {
      ShowAlert('위치를 먼저 선택해주세요');
      return;
    }
    openBarcodeReader({ onReadCode });
  };

  return (
    <Button
      size='md'
      rounded='lg'
      className={cn('px-2.5 h-9 gap-1', className)}
      variant='outline'
      disabled={!hasReader}
      onClick={onClick}
    >
      <PiBarcode className='h-4 w-4' />
      {text && <div>{text}</div>}
    </Button>
  );
}
