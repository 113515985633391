'use strict';


function localGetItem(_key: string) {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(_key);
    }
    else {
        console.error('cannot access localstroage in server');
        try {
            throw new Error("call stack");
        } catch (e: any) {
            console.log(e.stack);
        }
    }
}
function localSetItem(_key: string, _value: any) {
    if (typeof window !== 'undefined') {
        return localStorage.setItem(_key, _value)
    }
    else {
        console.error('cannot access localstroage in server');
        try {
            throw new Error("call stack");
        } catch (e: any) {
            console.log(e.stack);
        }
    }
}


export function getLocalStorage(_key: string, _default?: any) {
    try {
        // _key = __key_name(_key);
        const val = localGetItem(_key);

        if ((_default !== undefined) && val == null) return _default;
        return val;
    } catch (error) {
        console.error('getLocalStorage', error);
        return null;
    }
}

export function getLocalStorageEx(_key: string, _toJson?: boolean, _default?: any) {
    try {
        // _key = __key_name(_key);
        const val = localGetItem(_key);

        if (_toJson) {
            if (val) {
                try {
                    return JSON.parse(val);
                } catch (error) { /* empty */ }
            }
            return _default;
        }
        else {
            if (_default && !val) return _default;
            return val;
        }
    } catch (error) {
        console.error('getLocalStorage', error);
        if (_toJson) return {};
        return null;
    }
}


export function setLocalStorage(_key: string, keyValue: string) {
    try {
        // _key = __key_name(_key);
        localSetItem(_key, keyValue)
    } catch (error) {
        console.error('setLocalStorage', error);
    }
}

//로컬에 저장하는데, JSON을 변환하고, 필터가 있으면 그것만 저장한다.
//_filter_keys: 저장할 key의 array
export function setLocalStorageEx(_key: string, _save_value: any, _toJson: boolean, _filter_keys?: Array<string>) {
    try {
        let save_value = _save_value;
        if (_toJson && _save_value) {
            if (_filter_keys) {
                const filtered: any = {};
                for (const k of _filter_keys) {
                    filtered[k] = _save_value[k];
                }
                save_value = JSON.stringify(filtered);
            }
            else {
                save_value = JSON.stringify(_save_value);
            }
        }

        // _key = __key_name(_key);
        localSetItem(_key, save_value)
    } catch (error) {
        console.error('setLocalStorageEx', error);
    }
}


export function deleteLocalStorage(_key: string) {
    try {
        // _key = __key_name(_key);
        localStorage.removeItem(_key);

    } catch (error) {
        console.error('deleteLocalStorage', error);
    }
}

export function clearLocalStorage() {
    try {
        localStorage.clear()

    } catch (error) {
        console.error('clearLocalStorage', error);
    }
}

//bool 형으로 사용가능하게 쓴다.
export function getLocalStorageBool(_key: string, _default = false) {
    const _val = getLocalStorage(_key);
    return _val ? _val == '1' : _default;
}

//저장
//_empty_remove : 없으면 지운다.
export function setLocalStorageBool(_key: string, _val: boolean) {
    setLocalStorage(_key, _val ? '1' : '0');
}

//_val값이 false이면 지운다
export function setLocalStorageBoolR(_key: string, _val: boolean) {
    if (_val)
        setLocalStorage(_key, '1');
    else
        deleteLocalStorage(_key);
}

/**
 * 특정 개수만큼 array로 저장하고 읽는넘
 * 
 * {prefix}, 총개수를 위해 index를 array로 저장. 최대 max_count
 * 상세 내용은 {prefix}_{id} 로 저장
 */
type TLocalStroageMaxArray = {
    prefix: string
    max_count: number

    //item 저장
    set: (id: number | string, data: string) => void

    //item 가져오기
    get: (id: number | string, _toJson?: boolean, _default?: object) => any

    //item 삭제
    del: (id: number | string) => void
}

export function createLocalStroageMaxArray(prefix: string, max_count: number): TLocalStroageMaxArray {
    const self: TLocalStroageMaxArray = {
        prefix: prefix,
        max_count: max_count,

        set: (id: number | string, data: string) => {
            //인덱스를 먼저 보자.
            let saved_idx = getLocalStorageEx(prefix, true, null) as Array<number | string>;
            if (saved_idx) {
                //일단 찾아 보자. - 있으면 지운다.
                for (let i = 0; i < saved_idx.length; i++) {
                    if (saved_idx[i] == id) {
                        saved_idx.splice(i, 1);
                        break;
                    }
                }
            }
            else {
                saved_idx = [];
            }
            //마지막에 넣기
            saved_idx.push(id);

            //최대 개수를 초과하면 지우자.
            if (saved_idx.length > max_count) {
                const del_list = saved_idx.splice(0, max_count - saved_idx.length);
                del_list.forEach((_id) => {
                    deleteLocalStorage(prefix + '_' + _id);
                });
            }
            setLocalStorage(prefix, JSON.stringify(saved_idx));

            //이제 새것 저장.
            setLocalStorage(prefix + '_' + id, data);
        },

        get: (id: number | string, _toJson?: boolean, _default?: object) => {
            return getLocalStorageEx(prefix + '_' + id, _toJson, _default);
        },

        del: (id: number | string) => {
            //일단 삭제
            deleteLocalStorage(prefix + '_' + id);

            //인덱스 보자.
            const saved_idx = getLocalStorageEx(prefix, true, null) as Array<number | string>;
            if (saved_idx) {
                //일단 찾아 보자. - 있으면 지운다.
                for (let i = 0; i < saved_idx.length; i++) {
                    if (saved_idx[i] == id) {
                        saved_idx.splice(i, 1);
                        setLocalStorage(prefix, JSON.stringify(saved_idx));
                        return;
                    }
                }
            }
        },
    }
    return self;
}

// 기본 함수들
////////////////////////////////////////////////////////////////////////////////////
const cookie_default_path = ';path=/';
export function deleteDocumentCookie(cookieName: string) {
    try {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() - 1); //어제날짜를 쿠키 소멸날짜로 설정
        document.cookie = cookieName + "= " + "; expires=" + expireDate.toUTCString() + cookie_default_path;

    } catch (error) {
        console.error('deleteCookie', error);
    }
}

export function getDocumentCookie(cookieName: string) {
    try {
        // let my_cookieName = cookieName;
        cookieName = cookieName + '=';
        const decodedCookie = document.cookie;
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookieName) == 0) {
                return decodeURIComponent(c.substring(cookieName.length, c.length));
            }
        }
        return '';

        // let cookieData = document.cookie;
        // let start = cookieData.indexOf(cookieName);
        // let cookieValue = '';
        // if (start != -1) {
        //     start += cookieName.length;
        //     let end = cookieData.indexOf(';', start);
        //     if (end == -1) end = cookieData.length;
        //     cookieValue = cookieData.substring(start, end);
        // }
        // return decodeURIComponent(cookieValue);
    } catch (error) {
        console.error('getCookie', error);
    }
}

export function setDocumentCookie(cookieName: string, value: string, exseocnds = 3600 * 24 * 365) {
    try {
        const exdate = new Date();
        exdate.setTime(exdate.getTime() + 1000 * exseocnds)
        const cookieValue = encodeURIComponent(value) + ((exseocnds == null) ? "" : "; expires=" + exdate.toUTCString()) + cookie_default_path;
        document.cookie = cookieName + "=" + cookieValue;
    } catch (error) {
        console.error('setCookie', error);
    }
}
